import styled from "styled-components"

export const Content1280 = styled.div`
  //width: 888px;
  @media (max-width: 768px) {
    //width: 888px;
  }
  @media (max-width: 1281px) {
    max-width: 1280px;
  }
  @media (min-width: 1281px) {
    //width: 1280px;
    max-width: 1280px;
    //margin: 0 auto;
  }
`
